import { useEffect, useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import PageLayout from "../../components/layouts/PageLayout";
import Input from "../../components/reusable/Input";
import TicketManagementTable from "../../components/tables/ticket-management/TicketManagementTable";

import Tickets from "../../services/api/ticketsApi";
import Common from "../../services/api/commonApi";

export default function TicketManagement() {
  const storedSearch = localStorage.getItem("ticketSearch");
  const initialSearch = storedSearch ? storedSearch : "";

  const [search, setSearch] = useState(initialSearch);

  const storedCompanyName = localStorage.getItem("ticketManagementCompanyName");
  const initialCompanyName = storedCompanyName
    ? JSON.parse(storedCompanyName)
    : "";

  const [companyName, setCompanyName] = useState(initialCompanyName);

  const storedFromDate = localStorage.getItem("ticketManagementFromDate");
  const initialFromDate = storedFromDate ? storedFromDate : "";

  const [fromDate, setFromDate] = useState(initialFromDate);

  const storedToDate = localStorage.getItem("ticketManagementToDate");
  const initialToDate = storedToDate ? storedToDate : "";
  const [toDate, setToDate] = useState(initialToDate);

  const [ticketsList, setTicketsList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") == "supervisor" ||
      localStorage.getItem("userTypeFleetrax") == "admin" ||
      localStorage.getItem("userTypeFleetrax") == "lapiz" ||
      localStorage.getItem("userTypeFleetrax") == "metayb"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("ticketSearch", search);
  }, [search]);

  window.onbeforeunload = () => {
    localStorage.removeItem("ticketSearch");
    localStorage.removeItem("ticketManagementCompanyName");
    localStorage.removeItem("ticketManagementFromDate");
    localStorage.removeItem("ticketManagementToDate");
  };

  const fetchTicketsData = async () => {
    try {
      const params = {
        searchQuery: search,
        companyId: companyName.id,
        fromDate: fromDate,
        toDate: toDate,
      };
      const allTickets = await Tickets.findAllTickets(params);
      setTicketsList(allTickets);
    } catch (error) {
      console.error("Error fetching tickets data", error);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const allCompany = await Common.company();
      setCompanyList(allCompany);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  useEffect(() => {
    fetchCompanyData();
    fetchTicketsData();
  }, [companyName, fromDate, search, toDate]);

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Ticket Management"}
        description={"View your ticket details here."}
        paddingRight={true}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className="flex gap-8 mt-7 mr-3">
          <Input
            label={"Search"}
            type={"search"}
            placeholder={"employee name / ID"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <DropDown
            label={"Select Company"}
            placeholder={"Select company name"}
            displayValue={"name"}
            data={companyList}
            value={companyName}
            setValue={setCompanyName}
            setLocalStorage={"ticketManagementCompanyName"}
            selectAll
          />
          <DateInput
            label={"From date"}
            placeholder={"Select from date"}
            value={fromDate}
            setValue={setFromDate}
            setLocalStorage={"ticketManagementFromDate"}
          />
          <DateInput
            label={"To date"}
            placeholder={"Select to date"}
            value={toDate}
            setValue={setToDate}
            setLocalStorage={"ticketManagementToDate"}
          />
        </div>
        <div className="w-full flex mt-7">
          <TicketManagementTable data={ticketsList} />
        </div>
      </PageLayout>
    </>
  );
}
