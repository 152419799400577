import { useEffect, useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { Modal, Typography, message } from "antd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import PageLayout from "../../components/layouts/PageLayout";
import addTrip from "../trip-management/assets/add-trip.png";
import Input from "../../components/reusable/Input";
import TextFiled from "../../components/reusable/TextFeild";
import Button from "../../components/reusable/Button";
import ExpenseTable from "../../components/tables/expense-Page/ExpenseTable";
import Expense from "../../services/api/expenseApi";
import Common from "../../services/api/commonApi";

export default function ExpensePage() {
  const [expenseData, setExpenseData] = useState([]);
  const [payerList, setpayerList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [paidBy, setPaidBy] = useState("");
  const [date, setDate] = useState("");
  const [expenses, setExpenses] = useState("");
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");

  const [paidByError, setPaidByError] = useState("");
  const [dateError, setDateError] = useState("");
  const [expensesError, setExpensesError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [reasonError, setReasonError] = useState("");

  const storedExpenseFilter = localStorage.getItem("expenseFilter");
  const initialExpenseFilter = storedExpenseFilter
    ? JSON.parse(storedExpenseFilter)
    : "";

  const [expenseFilter, setExpenseFilter] = useState(initialExpenseFilter);

  const storedFromDate = localStorage.getItem("expenceFromDate");
  const initialFromDate = storedFromDate ? storedFromDate : "";

  const [fromDate, setFromDate] = useState(initialFromDate);

  const storedToDate = localStorage.getItem("expenceToDate");
  const initialToDate = storedToDate ? storedToDate : "";

  const [toDate, setToDate] = useState(initialToDate);

  window.onbeforeunload = () => {
    localStorage.removeItem("expenseFilter");
    localStorage.removeItem("expenceFromDate");
    localStorage.removeItem("expenceToDate");
  };
  useEffect(() => {
    if (
      localStorage.getItem("userTypeFleetrax") == "supervisor" ||
      localStorage.getItem("userTypeFleetrax") == "admin" ||
      localStorage.getItem("userTypeFleetrax") == "lapiz" ||
      localStorage.getItem("userTypeFleetrax") == "metayb"
    ) {
      alert("You are not authorized to access this page");
      window.location.href = "/";
    }
  }, []);

  const expenseDropDownData = [
    { value: "Fuel", label: "Fuel" },
    { value: "Stationary", label: "Stationary" },
    { value: "Office Rent", label: "Office Rent" },
    { value: "Travel Allowance", label: "Travel Allowance" },
    { value: "Broadband Charges", label: "Broadband Charges" },
    { value: "Mobile Bill", label: "Mobile Bill" },
    { value: "Creditcard Bill", label: "Creditcard Bill" },
    { value: "Electricity Bill", label: "Electricity Bill" },
    { value: "Advance", label: "Advance" },
    { value: "Outstation", label: "Outstation" },
    { value: "Settlement", label: "Settlement" },
    { value: "Others", label: "Others" },
  ];

  useEffect(() => {
    fetchExpenseData();
    fetchPayerData();
  }, []);

  useEffect(() => {
    fetchExpenseData();
  }, [expenseFilter, fromDate, toDate]);

  const fetchExpenseData = async () => {
    try {
      const params = {
        expensesType: expenseFilter?.value,
        fromDate: fromDate,
        toDate: toDate,
      };
      const response = await Expense.getExpenses(params);
      setExpenseData(
        response?.length > 0 &&
          response?.map((item, index) => {
            item.sNo = index + 1;
            return item;
          })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPayerData = async () => {
    try {
      const data = await Common.getPayer();
      setpayerList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const isValid = () => {
    let isValid = true;

    if (!paidBy) {
      setPaidByError("Please select Paid by");
      isValid = false;
    } else {
      setPaidByError("");
    }
    if (!date) {
      setDateError("Please select Date");
      isValid = false;
    } else {
      setDateError("");
    }
    if (!expenses) {
      setExpensesError("Please select Expenses");
      isValid = false;
    } else {
      setExpensesError("");
    }
    if (!amount) {
      setAmountError("Please enter Amount Paid");
      isValid = false;
    } else {
      setAmountError("");
    }
    if (!reason) {
      setReasonError("Please enter Reason");
      isValid = false;
    } else {
      setReasonError("");
    }

    return isValid;
  };

  const AddEmployeeModalContent = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-6">
          <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
            Add Expense
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <DropDown
                label={"Paid By"}
                placeholder={"Select Payer Name"}
                displayValue={"payerName"}
                data={payerList}
                value={paidBy}
                setValue={setPaidBy}
                error={paidByError}
                mandate={true}
              />
              <DateInput
                label={"Date"}
                placeholder={"Select date"}
                value={date}
                setValue={setDate}
                error={dateError}
                width={"290px"}
                mandate={true}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <DropDown
                label={"Expenses"}
                placeholder={"Select Expenses"}
                displayValue={"label"}
                data={expenseDropDownData}
                value={expenses}
                setValue={setExpenses}
                error={expensesError}
                mandate={true}
              />
              <Input
                label={"Amount"}
                placeholder={"Enter Amount Paid"}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                error={amountError}
                mandate={true}
                width={"290px"}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <TextFiled
                label={"Reason"}
                placeholder={"Enter Reason"}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                error={reasonError}
                mandate={true}
                rows={3}
              />
            </div>
          </div>
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                onClickSubmit();
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const onClickSubmit = async () => {
    if (!isValid()) {
      return;
    }
    try {
      const data = {
        paidBy: parseInt(paidBy?.id),
        amount: parseInt(amount),
        paidDate: date,
        expenses: expenses?.value,
        reason: reason,
      };
      const response = await Expense.createExpenses(data);
      if (response.status) {
        fetchExpenseData();
        message.success("Expense added successfully");
        setPaidBy("");
        setDate("");
        setExpenses("");
        setAmount("");
        setReason("");
        setShowModal(false);
      }
    } catch (error) {
      message.error(error.message);
      console.error("Error fetching data", error);
    }
  };

  const handleAddExpenseModal = () => {
    setShowModal(true);
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  return (
    <>
      <PageLayout
        title={"Expenses"}
        description={"View your expense details here."}
        paddingRight={true}
        buttonTitle={"Add Expense"}
        BreadcrumbsUrl={BreadcrumbsUrl}
        setAddTripModal={() => {
          handleAddExpenseModal();
        }}
      >
        <div className="flex gap-8 mt-7">
          <DropDown
            label={"Expenses"}
            placeholder={"Select Expenses"}
            displayValue={"label"}
            data={expenseDropDownData}
            value={expenseFilter}
            setValue={setExpenseFilter}
            setLocalStorage={"expenseFilter"}
            selectAll
          />
          <DateInput
            label={"From date"}
            placeholder={"Select from date"}
            value={fromDate}
            setValue={setFromDate}
            setLocalStorage={"expenceFromDate"}
            mandate
          />
          <DateInput
            label={"To date"}
            placeholder={"Select to date"}
            value={toDate}
            setValue={setToDate}
            setLocalStorage={"expenceToDate"}
            mandate
          />
        </div>
        <div className="w-full flex mt-7">
          <ExpenseTable data={expenseData} />
        </div>
      </PageLayout>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        width={"750px"}
        className="my-8"
        footer={null}
        centered
      >
        {AddEmployeeModalContent()}
      </Modal>
    </>
  );
}
