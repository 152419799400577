import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Modal, Typography, message } from "antd";
import { Card, Popover } from "antd";
import { Breadcrumbs } from "@mui/material";
import PageLayout from "../../components/layouts/PageLayout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Common from "../../services/api/commonApi";
import Employee from "../../services/api/employeeApi";
import Button from "../../components/reusable/Button";
import DateUtils from "../../services/utility/DateUtils";
import ConfirmModal from "../../components/modals/ConfirmModal";
import SuccessModal from "../../components/modals/SuccessModal";
import edit from "../../assets/edit.svg";

export default function EmployeeDetailsPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const paramsEmployeeID = params.get("id");

  const [disableButton, setDisableButton] = useState(false);
  const [editEmployeeModal, setEditEmployeeModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [bloodGroupID, setBloodGroupID] = useState("");

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [stateName, setStateName] = useState("");
  const [stateID, setStateID] = useState("");

  const [districtID, setDistrictID] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [pincode, setPincode] = useState("");
  const [streetName, setStreetName] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [createdTime, setCreatedTime] = useState("");

  const [disabled, setDisabled] = useState(true);

  const [employeeIdError, setEmployeeIdError] = useState("");
  const [employeeNameError, setEmployeeNameError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [bloodGroupError, setBloodGroupError] = useState("");
  const [stateNameError, setStateNameError] = useState("");
  const [districtNameError, setDistrictNameError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [streetNameError, setStreetNameError] = useState("");
  const [houseNumberError, setHouseNumberError] = useState("");
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [pickupLocationError, setPickupLocationError] = useState("");
  const [dropLocationError, setDropLocationError] = useState("");
  const [companyID, setCompanyID] = useState("");

  const navigator = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);

  const [createdBy, setCreatedBy] = useState("");

  const storedUser = localStorage.getItem("userTypeFleetrax");
  const initialUser = storedUser || "";
  const [userCompany, setUserCompany] = useState(initialUser);

  const fetchEmployeeData = async () => {
    try {
      const oneEmployee = await Employee.findOneEmployee({
        id: paramsEmployeeID,
      });
      setCreatedBy(oneEmployee?.ccUser?.name);
      setEmployeeData(oneEmployee);
      setCompany(oneEmployee.company.name);
      setEmployeeId(oneEmployee.employeeID);
      setEmployeeName(oneEmployee.employeeName);
      setGender(oneEmployee.gender);
      setMobileNumber(oneEmployee.mobileNumber);
      setWhatsappNumber(oneEmployee.whatsappNumber);
      setEmail(oneEmployee.email);
      setAge(oneEmployee.age);
      setDateOfBirth(oneEmployee.dob);
      setBloodGroup(oneEmployee.bloodGroup.name);
      setLatitude(oneEmployee.latitude);
      setLongitude(oneEmployee.longitude);
      setStateName(oneEmployee.state.stateName);
      setDistrictName(oneEmployee.district.districtName);
      setPincode(oneEmployee.pincode);
      setStreetName(oneEmployee.streetName);
      setHouseNumber(oneEmployee.houseNumber);
      setPickupLocation(oneEmployee.pickUpLocation);
      setDropLocation(oneEmployee.dropLocation);
      setCreatedDate(DateUtils.convertDate(oneEmployee.createdAt));
      setCreatedTime(DateUtils.convertTime(oneEmployee.createdAt));
      setBloodGroupID(oneEmployee.bloodGroup.id);
      setStateID(oneEmployee.state.id);
      setDistrictID(oneEmployee.district.id);
      setCompanyID(oneEmployee.company.id);
    } catch (error) {
      console.error("Error fetching driver data", error);
    }
  };
  console.log(createdBy);
  useEffect(() => {
    fetchEmployeeData();
    fetchStateData();
    fetchCompanyData();
    fetchBloodGroupData();
  }, []);

  useEffect(() => {
    if (stateID) {
      fetchDistrictData();
    }
  }, [stateID]);

  const fetchStateData = async () => {
    try {
      const data = await Common.state();
      setStateList(data);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  const fetchDistrictData = async () => {
    try {
      const data = await Common.district(stateID.id);
      setDistrictList(data);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const allCompany = await Common.company();
      setCompanyList(allCompany);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  const fetchBloodGroupData = async () => {
    try {
      const allBloodGroup = await Common.bloodGroup();
      setBloodGroupList(allBloodGroup);
    } catch (error) {
      console.error("Error fetching blood-group data", error);
    }
  };

  // function for delete inside action
  function handleDeleteModal() {
    setConfirmModal(!confirmModal);
  }

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const handleSubmit = async () => {
    let isValid = true;

    if (!employeeId) {
      setEmployeeIdError("Please enter employee id");
      isValid = false;
    } else {
      setEmployeeIdError("");
    }

    if (!employeeName) {
      setEmployeeNameError("Please enter employee name");
      isValid = false;
    } else {
      setEmployeeNameError("");
    }

    if (!company) {
      setCompanyError("Please select company");
      isValid = false;
    } else {
      setCompanyError("");
    }

    if (!gender) {
      setGenderError("Please select gender");
      isValid = false;
    } else {
      setGenderError("");
    }

    if (!mobileNumber) {
      setMobileNumberError("Please enter mobile number");
      isValid = false;
    } else {
      setMobileNumberError("");
    }

    if (!pickupLocation) {
      setPickupLocationError("Please enter pickup location");
      isValid = false;
    } else {
      setPickupLocationError("");
    }

    if (isValid) {
      setDisableButton(true);
      const data = {
        id: parseInt(paramsEmployeeID),
        companyID: companyID.id ? companyID.id : employeeData.company.id,
        employeeId: employeeId,
        employeeName: employeeName,
        gender: gender.option ? gender.option : gender,
        mobileNumber: mobileNumber,
        whatsappNumber: whatsappNumber,
        email: email,
        age: age,
        dateOfBirth: dateOfBirth,
        bloodGroupID: bloodGroupID.id ? bloodGroupID.id : bloodGroupID,
        latitude: latitude,
        longitude: longitude,
        stateID: stateID.id ? stateID.id : stateID,
        districtID: districtID.id ? districtID.id : districtID,
        pincode: pincode,
        streetName: streetName,
        houseNumber: houseNumber,
        pickUpLocation: pickupLocation,
      };
      try {
        const response = await Employee.updateEmployee(data);
        if (response.code === 200) {
          setEditEmployeeModal(!editEmployeeModal);
          fetchEmployeeData();
          message.success("Employee updated successfully");
        }
      } catch (error) {
        console.error("Error fetching community data", error);
      }
      setDisableButton(false);
    }
  };

  const handleDelete = async () => {
    try {
      const deletedBy = 1;
      const response = await Employee.deleteEmployee(
        paramsEmployeeID,
        deletedBy
      );
      if (response.code === 200) {
        setConfirmModal(!confirmModal);
        setTimeout(() => {
          setDeleteModal(!deleteModal);
          navigate("/employee-management");
        }, 2000);
      }
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  const menuIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
      >
        <path
          d="M1.83333 8.5C2.29357 8.5 2.66667 8.1269 2.66667 7.66667C2.66667 7.20643 2.29357 6.83333 1.83333 6.83333C1.3731 6.83333 1 7.20643 1 7.66667C1 8.1269 1.3731 8.5 1.83333 8.5Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 2.66667C2.29357 2.66667 2.66667 2.29357 2.66667 1.83333C2.66667 1.3731 2.29357 1 1.83333 1C1.3731 1 1 1.3731 1 1.83333C1 2.29357 1.3731 2.66667 1.83333 2.66667Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83333 14.3333C2.29357 14.3333 2.66667 13.9602 2.66667 13.5C2.66667 13.0398 2.29357 12.6667 1.83333 12.6667C1.3731 12.6667 1 13.0398 1 13.5C1 13.9602 1.3731 14.3333 1.83333 14.3333Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const actionButton = (
    <button className="flex flex-col">
      <button
        className=" flex w-full items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
        onClick={() => setEditEmployeeModal(!editEmployeeModal)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Edit</p>
      </button>

      <button
        className="flex items-center hover:bg-gray-200 pl-2 rounded-md pr-3 gap-3 py-1"
        onClick={handleDeleteModal}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M2.09473 3.49984H3.28863M3.28863 3.49984H12.8399M3.28863 3.49984V11.6665C3.28863 11.9759 3.41442 12.2727 3.63832 12.4915C3.86222 12.7103 4.1659 12.8332 4.48254 12.8332H10.4521C10.7687 12.8332 11.0724 12.7103 11.2963 12.4915C11.5202 12.2727 11.646 11.9759 11.646 11.6665V3.49984H3.28863ZM5.0795 3.49984V2.33317C5.0795 2.02375 5.20528 1.72701 5.42918 1.50821C5.65308 1.28942 5.95676 1.1665 6.2734 1.1665H8.66122C8.97786 1.1665 9.28154 1.28942 9.50544 1.50821C9.72934 1.72701 9.85513 2.02375 9.85513 2.33317V3.49984M6.2734 6.4165V9.9165M8.66122 6.4165V9.9165"
            stroke="#344054"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Delete</p>
      </button>
    </button>
  );

  const PersonalDetails = () => {
    return (
      <>
        <div className=" w-full px-1 flex justify-center items-center ">
          <Card className=" w-full  mt-6  shadow-md  ">
            <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
              <div className=" w-full flex justify-between items-center">
                <div className=" w-full text-start text-lg mt-1 font-medium text-gray-800 ">
                  Personal Details
                </div>
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
                <Input
                  label={"Employee ID"}
                  placeholder={"-"}
                  value={employeeData?.employeeID}
                  readOnly
                />
                <Input
                  label={"Employee Name"}
                  placeholder={"-"}
                  value={employeeData?.employeeName}
                  readOnly
                />
                <Input
                  label={"Company Name"}
                  placeholder={"-"}
                  value={employeeData?.company?.name}
                  readOnly
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                <Input
                  label={"Gender"}
                  placeholder={"-"}
                  value={employeeData?.gender}
                  readOnly
                />
                <MobileNumberInput
                  type={"number"}
                  placeholder={"-"}
                  label={"Mobile Number"}
                  value={employeeData?.mobileNumber}
                  readOnly
                />
                <MobileNumberInput
                  type={"number"}
                  label={"Whatsapp Number"}
                  placeholder={"-"}
                  value={employeeData?.whatsappNumber}
                  readOnly
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10 ">
                <Input
                  label={"Email"}
                  placeholder={"-"}
                  value={employeeData?.email}
                  readOnly
                />
                <Input
                  type={"number"}
                  label={"Age"}
                  placeholder={"-"}
                  value={employeeData?.age}
                  readOnly
                />
                <DateInput
                  label={"Date of Birth"}
                  placeholder={"-"}
                  value={employeeData?.dob}
                  readOnly
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10 ">
                <Input
                  label={"Blood Group"}
                  placeholder={"-"}
                  value={
                    typeof employeeData?.bloodGroup === "object"
                      ? employeeData?.bloodGroup?.name
                      : employeeData?.bloodGroup
                  }
                  readOnly
                />
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };

  const AddressDetails = () => {
    return (
      <>
        <div className=" w-full px-1 flex justify-center items-center ">
          <Card className=" w-full  mt-6  shadow-md  ">
            <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
              <div className=" w-full flex justify-between items-center">
                <div className=" w-full text-start text-lg mt-1 font-medium text-gray-800 ">
                  Address Details
                </div>
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
                <Input
                  label={"State Name"}
                  placeholder={"-"}
                  value={
                    typeof employeeData?.state === "object"
                      ? employeeData?.state?.stateName
                      : employeeData?.state
                  }
                  readOnly
                />
                <Input
                  label={"District Name"}
                  placeholder={"-"}
                  value={
                    typeof employeeData?.district === "object"
                      ? employeeData?.district?.districtName
                      : employeeData?.district
                  }
                  readOnly
                />
                <Input
                  type={"number"}
                  label={"Pincode"}
                  placeholder={"-"}
                  value={employeeData?.pincode}
                  readOnly
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                <Input
                  label={"Street Name"}
                  placeholder={"-"}
                  value={employeeData?.streetName}
                  readOnly
                />
                <Input
                  label={"House Number"}
                  placeholder={"-"}
                  value={employeeData?.houseNumber}
                  readOnly
                />
                <Input
                  type={"number"}
                  label={"Latitude"}
                  placeholder={"-"}
                  value={employeeData?.latitude}
                  readOnly
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                <Input
                  type={"number"}
                  label={"Longitude"}
                  placeholder={"-"}
                  value={employeeData?.longitude}
                  readOnly
                />
                <Input
                  label={"Pickup Location"}
                  placeholder={"-"}
                  value={employeeData?.pickUpLocation}
                  readOnly
                />
                <Input
                  label={"Drop Location"}
                  placeholder={"-"}
                  value={employeeData?.dropLocation}
                  readOnly
                />
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };

  const CreateDetails = () => {
    return (
      <div className=" w-full px-1 flex  my-7 justify-center items-center ">
        <Card className=" w-full flex flex-col gap-6 pb-4  shadow-xl rounded-md">
          <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
            Creator Details
          </div>
          <>
            <div className=" w-full flex-wrap gap-5 h-fit py-8 flex justify-between items-center">
              <Input
                label={"Created by"}
                placeholder={"-"}
                value={createdBy}
                disabled={true}
              />
              <DateInput
                label={"Created date"}
                placeholder={"-"}
                value={DateUtils.convertDate(employeeData?.createdAt)}
                disabled={true}
              />
              <TimeInput
                label={"Created time"}
                placeholder={"-"}
                value={DateUtils.convertTime(employeeData?.createdAt)}
                disabled={true}
              />
            </div>
          </>
        </Card>
      </div>
    );
  };

  // modal for edit employee
  const EditEmployeeModal = () => {
    return (
      <>
        <div className=" w-full mt-6 mb-10">
          <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
            Edit Employee
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
              <Input
                label={"Employee ID"}
                placeholder={"Enter employee id"}
                value={employeeId}
                mandate
                onChange={(e) => setEmployeeId(e.target.value)}
                error={employeeIdError}
              />
              <Input
                label={"Employee Name"}
                placeholder={"Select employee name"}
                value={employeeName}
                mandate
                onChange={(e) => setEmployeeName(e.target.value)}
                error={employeeNameError}
              />
              {userCompany === "lapiz" ? (
                <DropDown
                  label={"Company Name"}
                  placeholder={"LAPIZ"}
                  value={"LAPIZ"}
                  mandate
                  disabled
                />
              ) : userCompany === "metayb" ? (
                <DropDown
                  label={"Company Name"}
                  placeholder={"METAYB"}
                  value={"METAYB"}
                  mandate
                  disabled
                />
              ) : (
                <DropDown
                  label={"Company Name"}
                  placeholder={"Select company name"}
                  displayValue={"name"}
                  data={companyList}
                  value={companyID}
                  setValue={setCompanyID}
                  mandate
                  error={companyError}
                />
              )}
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
              <DropDown
                label={"Gender"}
                placeholder={gender}
                displayValue={"option"}
                data={[{ option: "Male" }, { option: "Female" }]}
                value={gender}
                setValue={setGender}
              />
              <MobileNumberInput
                type={"number"}
                label={"Mobile Number"}
                placeholder={"Enter mobile number"}
                value={mobileNumber}
                setValue={setMobileNumber}
                mandate
                error={mobileNumberError}
              />
              <MobileNumberInput
                type={"number"}
                label={"Whatsapp Number"}
                placeholder={"Enter whatsapp number"}
                value={whatsappNumber}
                setValue={setWhatsappNumber}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10 ">
              <Input
                label={"Email"}
                placeholder={"Enter email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                type={"number"}
                label={"Age"}
                placeholder={"Enter age"}
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
              <DateInput
                label={"Date of Birth"}
                placeholder={"Select date"}
                value={dateOfBirth}
                setValue={setDateOfBirth}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10 ">
              <DropDown
                label={"Blood Group"}
                placeholder={bloodGroup ? bloodGroup : "Select blood group"}
                displayValue={"name"}
                data={bloodGroupList}
                value={bloodGroupID}
                setValue={setBloodGroupID}
              />
            </div>
            <hr className="w-full" />
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
              <DropDown
                label={"State Name"}
                placeholder={stateName ? stateName : "Select state name"}
                displayValue={"stateName"}
                data={stateList}
                value={stateID}
                setValue={setStateID}
              />
              <DropDown
                label={"District Name"}
                placeholder={districtName ? districtName : "Select district"}
                displayValue={"districtName"}
                data={districtList}
                value={districtID}
                setValue={setDistrictID}
              />
              <Input
                type={"number"}
                label={"Pincode"}
                placeholder={"Enter pincode"}
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
              <Input
                label={"Street Name"}
                placeholder={"Enter street name"}
                value={streetName}
                onChange={(e) => setStreetName(e.target.value)}
              />
              <Input
                label={"House Number"}
                placeholder={"Enter house number"}
                value={houseNumber}
                onChange={(e) => setHouseNumber(e.target.value)}
              />
              <Input
                type={"number"}
                label={"Latitude"}
                placeholder={"Enter latitude"}
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
              <Input
                type={"number"}
                label={"Longitude"}
                placeholder={"Enter longitude"}
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
              <Input
                label={"Pickup Location"}
                placeholder={"Enter pickup location"}
                value={pickupLocation}
                mandate
                onChange={(e) => setPickupLocation(e.target.value)}
                error={pickupLocationError}
              />
              <Input
                label={"Drop Location"}
                placeholder={"-"}
                value={company ? company : dropLocation}
                disabled={true}
              />
            </div>
          </div>
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={"Save Changes"}
              width={"170px"}
              onClick={handleSubmit}
              disabled={disableButton}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PageLayout
        title={"Employee Management"}
        description={"View your trip details here."}
        buttonWidth={"120px"}
        BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className="absolute right-16 top-20">
          <Popover
            content={actionButton}
            placement="leftTop"
            trigger="click"
            className="cursor-pointer"
          >
            {menuIcon()}
          </Popover>
        </div>
        <div className="pr-4">
          {PersonalDetails()}
          {AddressDetails()}
          {CreateDetails()}
        </div>
        {/* edit employee modal */}
        <Modal
          title={
            <>
              <img src={edit} width={40} alt="edit" />
            </>
          }
          visible={editEmployeeModal}
          width={1000}
          onCancel={() => setEditEmployeeModal(false)}
          footer={null}
          centered
          className="my-10"
        >
          {EditEmployeeModal()}
        </Modal>
        {/* confirmation modals */}
        <ConfirmModal
          isOpen={confirmModal}
          setIsOpen={setConfirmModal}
          title={"Delete ?"}
          description={"Do you want to delete this ?"}
          button1={"No"}
          button2={"Yes"}
          onClickButton1={() => setConfirmModal(!confirmModal)}
          onClickButton2={() => {
            setDeleteModal(!deleteModal);
            handleDelete();
          }}
        />
        <SuccessModal
          isOpen={deleteModal}
          setIsOpen={setDeleteModal}
          animation="delete"
          title={"deleted successfully"}
          onClose={() => navigator("/employee-management")}
        />
        {!disabled ? (
          <div className=" w-full flex justify-center items-center">
            <Button
              text={"Edit"}
              width={"170px"}
              height={"40px"}
              fontSize={"14px"}
              borderRadius={"6px"}
              color={"#1849A9"}
              onClick={() => handleSubmit()}
            />
          </div>
        ) : null}
      </PageLayout>
    </>
  );
}
