import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import DateInput from "../../reusable/DateInput";
import ExcelJS from "exceljs";
import Input from "../../reusable/Input";
import Reports from "../../../services/api/reportApi";
import Common from "../../../services/api/commonApi";
import DateUtils from "../../../services/utility/DateUtils";

export default function AttendanceTable() {

  const storedFromDate = localStorage.getItem("fromDateAttendance");
  const initialFromDate = storedFromDate ? storedFromDate : DateUtils.getTMinusDate(6);

  const [fromDate, setFromDate] = useState(initialFromDate);

  const storedToDate = localStorage.getItem("toDateAttendance");
  const initialToDate = storedToDate ? storedToDate : DateUtils.getCurrentDate();

  const [toDate, setToDate] = useState(initialToDate);

  const storedCompany = localStorage.getItem("companyAttendance");
  const initialCompany = storedCompany ? JSON.parse(storedCompany) : "";

  const [company, setCompany] = useState(initialCompany);

  const storedRouteId = localStorage.getItem("routeIdAttendance");
  const initialRouteId = storedRouteId ? storedRouteId : "";


  const [routeId, setRouteId] = useState(initialRouteId);

  const storedTripType = localStorage.getItem("tripTypeAttendance");
  const initialTripType = storedTripType ? JSON.parse(storedTripType) : "";

  const [tripType, setTripType] = useState(initialTripType);

  const [companyList, setCompanyList] = useState([]);
  const [tripTypeList, setTripTypeList] = useState([]);
  const [accountsData, setAccountsData] = useState([]);

  const storedUser = localStorage.getItem("userTypeFleetrax");
  const initialUser = storedUser || "";
  const [userCompany, setUserCompany] = useState(initialUser);

  window.onbeforeunload = () => {
    localStorage.removeItem("fromDateAttendance");
    localStorage.removeItem("toDateAttendance");
    localStorage.removeItem("companyAttendance");
    localStorage.removeItem("routeIdAttendance");
    localStorage.removeItem("tripTypeAttendance");
  };

  useEffect(() => {
    fetchCompanyData();
    fetchTripTypeData();
  }, []);

  useEffect(() => {
    fetchAccountsData();
    localStorage.setItem("routeIdAttendance", routeId);
  }, [company, fromDate, tripType, toDate, routeId]);

  const fetchAccountsData = async () => {
    try {
      const params = {
        routeIdAttendance: routeId,
        companyIdAttendance: userCompany === "lapiz" ? 3 : company?.id,
        tripTypeAttendance: tripType?.id,
        fromDateAttendance: fromDate,
        toDateAttendance: toDate,
      };
      const data = await Reports.getTripsAttendance(params);
      setAccountsData(data);
    } catch (error) {
      console.error("Error fetching reports data", error);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const data = await Common.company();
      setCompanyList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchTripTypeData = async () => {
    try {
      const data = await Common.tripType();
      setTripTypeList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const columnData =
    accountsData.length && accountsData.map((item, index) => {
      item.sNo = index + 1;
      return item;
    });

  const columns = [
    {
      id: "sNo",
      name: <TableHead width="80px">S.No</TableHead>,
      cell: (row) => (
        <TableCell width="80px">
          {row.sNo}
        </TableCell>
      ),
    },
    {
      id: "routeId",
      name: <TableHead>Route ID</TableHead>,
      cell: (row) => (
        <TableCell textCenter>
          {row.routeId || "-"}
        </TableCell>
      ),
    },
    {
      id: "routeName",
      name: <TableHead width="170px" position="left">Route Name</TableHead>,
      cell: (row) => <TableCell position="left" width="170px">{row.routeName || "-"}</TableCell>,
    },
    {
      id: "tripDate",
      name: <TableHead>Trip Date</TableHead>,
      cell: (row) => <TableCell>{row.tripDate ? DateUtils.convertDate(row.tripDate) : "-" || "-"}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type</TableHead>,
      cell: (row) => <TableCell>{row.tripType || "-"}</TableCell>,
    },
    {
      id: "shiftTime",
      name: <TableHead>Shift Time</TableHead>,
      cell: (row) => <TableCell>{row.shiftTime || "-"}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehicle Type</TableHead>,
      cell: (row) => <TableCell>{row.vehicleType || "-"}</TableCell>,
    },
    {
      id: "startTime",
      name: <TableHead>Start Time</TableHead>,
      cell: (row) => <TableCell>{row.startTime || "-"}</TableCell>,
    },
    {
      id: "endTime",
      name: <TableHead>End Time</TableHead>,
      cell: (row) => <TableCell>{row.endTime || "-"}</TableCell>,
    },
    {
      id: "allotedEmp",
      name: <TableHead>EMP Alloted</TableHead>,
      cell: (row) => <TableCell>{row.empAlloted >= 0 ? row.empAlloted : "-"}</TableCell>,
    },
    {
      id: "presentEmp",
      name: <TableHead>EMP Present</TableHead>,
      cell: (row) => <TableCell>{row.empPresent >= 0 ? row.empPresent : "-"}</TableCell>,
    },
    {
      id: "absentEmployees",
      name: <TableHead>EMP Absent</TableHead>,
      cell: (row) => (
        <TableCell>
          <p>{row.empAbsent || "-"}</p>
        </TableCell>
      ),
    },
    {
      id: "absentEmployeesNames",
      name: <TableHead width="250px" position="left">EMP Absent Names</TableHead>,
      cell: (row) => (
        <TableCell width="250px" position="left">
          <p>{row.empAbsentName || "-"}</p>
        </TableCell>
      ),
    },
  ];

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Trip Data");

    // Define header style
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFC0C0C0" } },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };

    // Define cell style
    const cellStyle = {
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: { vertical: "center", horizontal: "center" },
    };

    // Adding headers
    const headers = [
      "S.No", "Route ID", "Route Name", "Trip Date", "Trip Type", "Shift Time", "Vehicle Type", "Start Time", "End Time", "Allotted EMP", "Present EMP", "Absent Employees", "Absent Employees Names"
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Adding data rows
    accountsData.length && accountsData?.forEach((row, index) => {
      const dataRow = worksheet.addRow([
        index + 1, row.routeId, row.routeName, row.tripDate, row.tripType, row.shiftTime, row.vehicleType, row.startTime, row.endTime, row.empAlloted, row.empPresent, row.empAlloted - row.empPresent, row.empAbsentName
      ]);
      dataRow.eachCell((cell) => {
        cell.style = cellStyle;
      });
    });

    // Auto-fit columns
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        let columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 12 ? 12 : maxLength;
    });

    // Generate buffer and download the file
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `TripData_${new Date().toLocaleDateString()}.xlsx`;
    link.click();
  };

  return (
    <div className="w-full pb-5 text-base text-black">
      <div className="flex  flex-wrap lg:gap-x-8 2xl:gap-x-2 gap-2 w-full justify-center  lg:pl-2  lg:pr-10 lg:justify-start 2xl:justify-between items-center mb-5  lg:mb-5  mt-2 lg:mt-6">
        <Input
          type="search"
          label={"Route ID"}
          placeholder={"Enter route id"}
          value={routeId}
          onChange={(e) => { setRouteId(e.target.value) }}
        />
        {userCompany !== "lapiz" && userCompany !== "metayb" && (
          <DropDown
            label={"Company"}
            placeholder={"Select company"}
            displayValue={"name"}
            data={companyList}
            value={company}
            setValue={setCompany}
            setLocalStorage={"companyAttendance"}
            selectAll
          />
        )}
        <DropDown
          label={"Trip Type"}
          placeholder={"Select trip type"}
          displayValue={"name"}
          data={tripTypeList}
          value={tripType}
          setValue={setTripType}
          setLocalStorage={"tripTypeAttendance"}
          selectAll
        />
        <DateInput
          label={"From date"}
          placeholder={"Select from date"}
          value={fromDate}
          setValue={setFromDate}
          setLocalStorage={"fromDateAttendance"}
        />
        <DateInput
          label={"To date"}
          placeholder={"Select to date"}
          value={toDate}
          setValue={setToDate}
          setLocalStorage={"toDateAttendance"}
        />
      </div>
      <div className="flex justify-end w-[98.5%] pr-5   ">
        <div className="flex ">
          <button onClick={handleExport} className="flex  mb-5 ">
            <div className="bg-primary-blue/95 hover:bg-primary-blue flex justify-between items-center text-gray-100 text-xl font-semibold text-center w-full p-2 pl-4 pr-4 rounded-lg">
              <span className="mr-3">Download Now</span>
              <svg
                className="text-2xl "
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-download"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <Table columns={columns} rows={columnData} />
    </div>
  );
}
