import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, message } from "antd";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import PageLayout from "../../components/layouts/PageLayout";
import addTrip from "../trip-management/assets/add-trip.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import Button from "../../components/reusable/Button";
import Table from "../../components/reusable/Table/Table";
import TableCell from "../../components/reusable/Table/TableHead";
import TableHead from "../../components/reusable/Table/TableHead";
import Common from "../../services/api/commonApi";
import Employee from "../../services/api/employeeApi";
import Select from "react-select";
import Trip from "../../services/api/tripApi";
import SuccessModal from "../../components/modals/SuccessModal";
import DateUtils from "../../services/utility/DateUtils";
import RoutesTable from "./RoutesTable";

export default function RoutesPage() {
    const [addTripModal, setAddTripModal] = useState(false);
    const [addTripModal2, setAddTripModal2] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [companyList, setCompanyList] = useState([]);
    const [company, setCompany] = useState({ id: 2, name: "R1 RCM" });
    const [tripTypeList, setTripTypeList] = useState([]);
    const [tripType, setTripType] = useState({ id: 1, name: "Pickup" });
    const [vehicleTypeData, setVehicleTypeData] = useState([]);
    const [vehicleType, setVehicleType] = useState({ id: 1, name: "Sedan" });
    const [shiftTime, setShiftTime] = useState({ id: 2, name: "18:00 - 03:30" });
    const [shiftTimeList, setShiftTimeList] = useState([]);
    const [routeId, setRouteId] = useState("");
    const [tripAmount, setTripAmount] = useState("");
    const [routeName, setRouteName] = useState("");
    const [tripDate, setTripDate] = useState(DateUtils.getCurrentDate());
    const [employeeList, setEmployeeList] = useState([]);
    const [numberOfPickup, setNumberOfPickup] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [routeIdError, setRouteIdError] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [tripAmountError, setTripAmountError] = useState("");
    const [routeNameError, setRouteNameError] = useState("");
    const [tripDateError, setTripDateError] = useState("");
    const [shiftTimeError, setShiftTimeError] = useState("");
    const [tripTypeError, setTripTypeError] = useState("");
    const [vehicleTypeError, setVehicleTypeError] = useState("");
    const [numberOfPickupError, setNumberOfPickupError] = useState("");
    const [tripList, setTripList] = useState([]);
    const [viewPickUpTime, setViewPickUpTime] = useState([]);
    const [pickupTimeAndRow, setPickupTimeAndRow] = useState([
        { row: null, pickUpTime: "" },
    ]);

    const storedCompanyID = localStorage.getItem("tripCompanyID");
    const initialCompanyID = storedCompanyID ? JSON.parse(storedCompanyID) : "";
    const [companyID, setCompanyID] = useState(initialCompanyID);

    const storedShiftTimeID = localStorage.getItem("tripShiftTimeID");
    const initialShiftTimeID = storedShiftTimeID
        ? JSON.parse(storedShiftTimeID)
        : "";
    const [shiftTimeID, setShiftTimeID] = useState(initialShiftTimeID);

    const storedAllocatedTypeID = localStorage.getItem("tripAllocatedTypeID");
    const initialAllocatedTypeID = storedAllocatedTypeID
        ? JSON.parse(storedAllocatedTypeID)
        : "";
    const [allocatedTypeFilter, setAllocatedTypeFilter] = useState(
        initialAllocatedTypeID
    );

    const storedTripTypeID = localStorage.getItem("tripTripTypeID");
    const initialTripTypeID = storedTripTypeID
        ? JSON.parse(storedTripTypeID)
        : "";
    const [tripTypeFilter, setTripTypeFilter] = useState(initialTripTypeID);

    const storedFromDate = localStorage.getItem("tripFromDate");
    const initialFromDate = storedFromDate
        ? storedFromDate
        : DateUtils.getCurrentDate();

    const [fromDate, setFromDate] = useState(initialFromDate);

    const storedToDate = localStorage.getItem("tripToDate");
    const initialToDate = storedToDate
        ? storedToDate
        : DateUtils.getCurrentDate();

    const [toDate, setToDate] = useState(initialToDate);
    const [tripStatus, setTripStatus] = useState(0);
    const [tripTypeID, setTripTypeID] = useState("");

    const [createdBy, setCreatedBy] = useState("");
    const [tripCount, setTripCount] = useState({});

    window.onbeforeunload = () => {
        localStorage.removeItem("tripCompanyID");
        localStorage.removeItem("tripShiftTimeID");
        localStorage.removeItem("tripAllocatedTypeID");
        localStorage.removeItem("tripTripTypeID");
        localStorage.removeItem("tripFromDate");
        localStorage.removeItem("tripToDate");
    };

    useEffect(() => {
        if (
            localStorage.getItem("userTypeFleetrax") != "superAdminPlus" &&
            localStorage.getItem("userTypeFleetrax") != "superadmin" &&
            localStorage.getItem("userTypeFleetrax") != "admin" &&
            localStorage.getItem("userTypeFleetrax") != "lapiz" &&
            localStorage.getItem("userTypeFleetrax") != "metayb"
        ) {
            alert("You are not authorized to access this page");
            window.location.href = "/";
        }
    }, []);

    useEffect(() => {
        fetchTripData();
        fetchTripCount();
    }, [
        companyID,
        shiftTimeID,
        fromDate,
        toDate,
        tripStatus,
        tripTypeID,
        allocatedTypeFilter,
        tripTypeFilter,
    ]);

    const fetchTripData = async () => {
        setLoading(true);
        try {
            const dataObj = {
                companyID: 3,
                shiftTimeID: shiftTimeID.id,
                fromDate: fromDate,
                toDate: toDate,
                // tripStatus: tripStatus,
                tripTypeID: tripTypeID,
                allocatedType: allocatedTypeFilter?.id,
                tripType: tripTypeFilter?.id,
            };
            const data = await Trip.getTrips(dataObj);
            setTripList(data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const fetchTripCount = async () => {
        try {
            const dataObj = {
                companyID: companyID.id,
                shiftTimeID: shiftTimeID.id,
                fromDate: fromDate,
                toDate: toDate,
                // tripStatus: tripStatus,
                // tripTypeID: tripTypeID,
                allocatedType: allocatedTypeFilter?.id,
                tripType: tripTypeFilter?.id,
            };
            const data = await Trip.getTripCount(dataObj);
            setTripCount(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const createTrip = async () => {
        try {
            const data = {
                routeId: routeId,
                companyId: 3,
                tripAmount: tripAmount,
                routeName: routeName?.toUpperCase(),
                tripDate: tripDate,
                shiftTime: shiftTime.id,
                tripType: tripType.id,
                vehicleType: vehicleType.id,
                noOfPickup: numberOfPickup,
                liveTracking: pickupTimeAndRow,
                createdBy: parseInt(createdBy),
            };
            const response = await Trip.createTrip(data);
            if (response.status === 200) {
                fetchTripData();
                message.success("Trip created successfully");
                // setCompany({ id: 2, name: "R1 RCM" });
                setTripType({ id: 1, name: "Pickup" });
                setVehicleType({ id: 1, name: "Sedan" });
                setShiftTime({ id: 2, name: "18:00 - 03:30" });
                setRouteId("");
                setTripAmount("");
                setRouteName("");
                setTripDate(DateUtils.getCurrentDate());
                setNumberOfPickup("");
                setSelectedEmployee([]);
                setPickupTimeAndRow([{ row: null, pickUpTime: "" }]);
                setViewPickUpTime([]);
                setAddTripModal(false);
                setAddTripModal2(false);
            } else {
                message.error(response);
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const handlePickupTime = (row, value, index) => {
        setViewPickUpTime((prevArr) => {
            const result = [...prevArr];
            result[index] = value;
            return result;
        });
        setPickupTimeAndRow((pickupTimeAndRow) => {
            const updatedVal = pickupTimeAndRow.map((item) => {
                if (item.row === row.id) {
                    return { ...item, pickUpTime: value };
                }
                return item;
            });
            if (!updatedVal.some((item) => item.row === row.id)) {
                updatedVal.push({ row: row.id, pickUpTime: value });
            }
            return updatedVal;
        });
    };

    useEffect(() => {
        var i = 0;
        var entry1;
        while (i < pickupTimeAndRow.length) {
            entry1 = pickupTimeAndRow[i];
            if (selectedEmployee.some((entry2) => entry1.row === entry2.value)) {
                ++i;
            } else {
                pickupTimeAndRow.splice(i, 1);
            }
        }

        setNumberOfPickup(selectedEmployee.length);
    }, [selectedEmployee]);

    const isValid = () => {
        let isValid = true;

        if (!routeId) {
            setRouteIdError("Please enter route id");
            isValid = false;
        } else {
            setRouteIdError("");
        }
        if (!company) {
            setCompanyError("Please select company");
            isValid = false;
        } else {
            setCompanyError("");
        }
        if (!tripAmount) {
            setTripAmountError("Please enter trip amount");
            isValid = false;
        } else {
            setTripAmountError("");
        }
        if (!routeName) {
            setRouteNameError("Please enter route name");
            isValid = false;
        } else {
            setRouteNameError("");
        }
        if (!tripDate) {
            setTripDateError("Please select trip date");
            isValid = false;
        } else {
            setTripDateError("");
        }
        if (!shiftTime) {
            setShiftTimeError("Please select shift time");
            isValid = false;
        } else {
            setShiftTimeError("");
        }
        if (!tripType) {
            setTripTypeError("Please select trip type");
            isValid = false;
        } else {
            setTripTypeError("");
        }
        if (!vehicleType) {
            setVehicleTypeError("Please select vehicle type");
            isValid = false;
        } else {
            setVehicleTypeError("");
        }
        if (tripType.id == 1 || tripType.id == 2) {
            if (!numberOfPickup || numberOfPickup === 0) {
                setNumberOfPickupError("Atleast one pickup is required");
                isValid = false;
            } else {
                setNumberOfPickupError("");
            }
        } else {
            setNumberOfPickupError("");
        }

        return isValid;
    };

    const handleSubmit = () => {
        if (isValid()) {
            if (pickupTimeAndRow.length === selectedEmployee.length) {
                createTrip();
            } else {
                alert("Please enter pickup time for all employees");
            }
        }
    };

    useEffect(() => {
        fetchCompanyData();
        fetchTripTypeData();
        fetchVehicleTypeData();
        fetchShiftTimeData();
        setCreatedBy(localStorage.getItem("userIdFleetrax"));
    }, []);

    useEffect(() => {
        fetchEmployeeData();
        setSelectedEmployee([]);
    }, [company]);

    const options = employeeList?.map((employee) => ({
        value: employee.id,
        label: employee.employeeID,
    }));
    const extractedIds = selectedEmployee?.map((employee) => employee?.value);

    const filteredEmployees = employeeList?.filter((employee) =>
        extractedIds?.includes(employee.id)
    );

    const employeeData = filteredEmployees?.map((employee, index) => {
        let pickupNodalPoint, dropLocation;

        if (tripType.id == 1 || tripType.id == 3) {
            pickupNodalPoint = employee.pickUpLocation;
            dropLocation = employee.dropLocation;
        } else if (tripType.id == 2 || tripType.id == 4) {
            pickupNodalPoint = employee.dropLocation;
            dropLocation = employee.pickUpLocation;
        }

        return {
            slNo: index + 1,
            id: employee.id,
            employeeId: employee.employeeID,
            employeeName: employee.employeeName,
            gender: employee.gender,
            contactNumber: employee.mobileNumber,
            pickupNodalPoint: pickupNodalPoint,
            dropLocation: dropLocation,
        };
    });

    const fetchEmployeeData = async () => {
        try {
            const dataObj = {
                companyId: 3,
            };
            const data = await Employee.findAllEmplyee(dataObj);
            setEmployeeList(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };
    const fetchCompanyData = async () => {
        try {
            const data = await Common.company();
            setCompanyList(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };
    const fetchTripTypeData = async () => {
        try {
            const data = await Common.tripType();
            setTripTypeList(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };
    const fetchVehicleTypeData = async () => {
        try {
            const data = await Common.vehicleType();
            setVehicleTypeData(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };
    const fetchShiftTimeData = async () => {
        try {
            const data = await Common.shiftTime();
            setShiftTimeList(data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    const BreadcrumbsUrl = () => (
        <>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Link color="inherit" href="/" onClick={handleClick}>
                    Trip Management
                </Link>
                <Link
                    color="inherit"
                    href="/getting-started/installation/"
                    onClick={handleClick}
                >
                    Not Allotted
                </Link>
                <Typography color="text.primary">LC007</Typography>
            </Breadcrumbs>
        </>
    );

    const employeeColumns = [
        {
            id: "slNo",
            name: <TableHead width="70px">SL.No</TableHead>,
            cell: (row) => <TableCell width="70px">{row.slNo}</TableCell>,
        },
        {
            id: "employeeId",
            name: <TableHead width="100px">Employee Id</TableHead>,
            cell: (row) => <TableCell width="70px">{row.employeeId}</TableCell>,
        },
        {
            id: "employeeName",
            name: <TableHead>Employee name</TableHead>,
            cell: (row) => (
                <TableCell position="center">{row.employeeName}</TableCell>
            ),
        },
        {
            id: "gender",
            name: <TableHead>Gender</TableHead>,
            cell: (row) => <TableCell width="100px"> {row.gender} </TableCell>,
        },
        {
            id: "contactNumber",
            name: <TableHead>Contact Number</TableHead>,
            cell: (row) => <TableCell> {row.contactNumber} </TableCell>,
        },
        {
            id: "pickupNodalPoint",
            name: <TableHead>Pickup Nodal Point</TableHead>,
            cell: (row, index) => (
                <div className=" w-full border-placeholder-gray   justify-center items-center rounded-lg ">
                    <input
                        type="text"
                        name="pickupNodalPoint"
                        id="pickupNodalPoint"
                        className={`border  text-sm w-[120px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]`}
                        placeholder={row.pickupNodalPoint}
                        disabled={true}
                        value={row.pickupNodalPoint}
                    />
                </div>
            ),
        },
        {
            id: "pickupTime",
            name: <TableHead>Pickup Time</TableHead>,
            cell: (row) => (
                <>
                    <div className="w-full flex justify-center items-center">
                        <div className="border-placeholder-gray justify-center items-center rounded-lg">
                            <input
                                type="time"
                                name="pickupTime"
                                id="pickupTime"
                                className={`border text-sm lg:text-ba w-[100px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]`}
                                value={viewPickUpTime[row.id]}
                                onChange={(e) => handlePickupTime(row, e.target.value, row.id)}
                            />
                        </div>
                    </div>
                </>
            ),
        },

        {
            id: "dropLocation",
            name: <TableHead>Drop Location</TableHead>,
            cell: (row, index) => (
                <>
                    <div className=" w-full  flex  justify-center items-center ">
                        <div className="border-placeholder-gray justify-center items-center rounded-lg ">
                            <input
                                type="text"
                                name="dropLocation"
                                id="dropLocation"
                                className={`border  text-sm w-[110px] text-text-black placeholder-placeholder-gray outline-none rounded-lg pl-2 h-[40px] focus:border-[#1849A9]`}
                                placeholder={row.pickupNodalPoint}
                                value={row.dropLocation}
                                disabled={true}
                            />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const Modal1Content = () => {
        return (
            <>
                <div className=" w-full  mt-6 mb-10  ">
                    <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
                        Create Trip
                    </div>
                    <div className=" w-full flex flex-col gap-3 lg:gap-6 py-10">
                        <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                            <Input
                                label={"Route ID"}
                                placeholder={"Enter route id"}
                                value={routeId}
                                setValue={setRouteId}
                                mandate={true}
                                onChange={(e) => setRouteId(e.target.value)}
                                error={routeIdError}
                            />
                            <DropDown
                                label={"Company"}
                                placeholder={"Select company name"}
                                defaultValue={"LAPIZ"}
                                disabled
                            />
                            {/* <Input
                                type={"number"}
                                label={"Trip amount"}
                                placeholder={"Enter trip amount"}
                                value={tripAmount}
                                setValue={setTripAmount}
                                mandate={true}
                                onChange={(e) => setTripAmount(e.target.value)}
                                error={tripAmountError}
                            /> */}
                            <Input
                                label={"Route name"}
                                placeholder={"Enter route name"}
                                value={routeName?.toUpperCase()}
                                setValue={setRouteName}
                                mandate={true}
                                onChange={(e) => setRouteName(e.target.value)}
                                error={routeNameError}
                            />
                        </div>
                        <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10 ">
                            <DateInput
                                label={"Trip date"}
                                placeholder={"Select trip date"}
                                value={tripDate}
                                setValue={setTripDate}
                                mandate={true}
                                onChange={(e) => setTripDate(e.target.value)}
                                error={tripDateError}
                            />
                            <DropDown
                                label={"Shift time"}
                                placeholder={"Select shift time"}
                                displayValue={"name"}
                                data={shiftTimeList}
                                value={shiftTime}
                                setValue={setShiftTime}
                                mandate={true}
                                onChange={(e) => setShiftTime(e.target.value)}
                                error={shiftTimeError}
                            />
                            <DropDown
                                label={"Trip type"}
                                placeholder={"Select trip type"}
                                displayValue={"name"}
                                data={tripTypeList}
                                value={tripType}
                                setValue={setTripType}
                                mandate={true}
                                onChange={(e) => setTripType(e.target.value)}
                                error={tripTypeError}
                            />
                        </div>
                        <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10 ">
                            <DropDown
                                label={"Vehicle type"}
                                placeholder={"Select vehicle type"}
                                displayValue={"name"}
                                data={vehicleTypeData}
                                value={vehicleType}
                                setValue={setVehicleType}
                                mandate={true}
                                onChange={(e) => setVehicleType(e.target.value)}
                                error={vehicleTypeError}
                            />
                            <div className="lg:min-w-[300px]">
                                <label className="text-[#344054] text-[15px] pl-1 font-medium">
                                    {"Select Employee(s)"}
                                </label>
                                <Select
                                    isMulti
                                    name="colors"
                                    options={options}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={setSelectedEmployee}
                                    value={selectedEmployee}
                                />
                            </div>
                            <Input
                                label={"Number of pickup"}
                                placeholder={"No of pickup"}
                                value={numberOfPickup}
                                setValue={setNumberOfPickup}
                                mandate={true}
                                onChange={(e) => setNumberOfPickup(e.target.value)}
                                disabled={true}
                                error={numberOfPickupError}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-6 pb-10">
                        <div className="w-full">
                            <Table
                                columns={employeeColumns}
                                rows={employeeData}
                                rowKey="id"
                                scroll={{ x: 800 }}
                                pagination={false}
                            />
                        </div>
                    </div>
                    <div className=" w-full flex gap-5  justify-center items-center">
                        <Button text={"Create"} width={"170px"} onClick={handleSubmit} />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <PageLayout
                title={"Trip Management"}
                description={"View your trip details here."}
                buttonTitle={"Add Trip"}
                buttonWidth={"fit-content"}
                paddingRight={true}
                BreadcrumbsUrl={BreadcrumbsUrl}
                setAddTripModal={setAddTripModal}
            >
                <div className=" w-full flex mt-7">
                    <RoutesTable
                        setCompanyID={setCompanyID}
                        setShiftTimeID={setShiftTimeID}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        companyID={companyID}
                        shiftTimeID={shiftTimeID}
                        fromDate={fromDate}
                        toDate={toDate}
                        companyList={companyList}
                        shiftTimeList={shiftTimeList}
                        tripList={tripList}
                        vehicleTypeData={vehicleTypeData}
                        tripTypeList={tripTypeList}
                        fetchTripData={fetchTripData}
                        allocatedTypeFilter={allocatedTypeFilter}
                        setAllocatedTypeFilter={setAllocatedTypeFilter}
                        tripTypeFilter={tripTypeFilter}
                        setTripTypeFilter={setTripTypeFilter}
                        isLoading={isLoading}
                    />
                </div>
            </PageLayout>

            <Modal
                title={
                    <>
                        <img src={addTrip} width={40} alt="" />
                    </>
                }
                visible={addTripModal}
                width={"1200px"}
                // onCancel={() => setAddTripModal(false)}
                onCancel={() => {
                    setAddTripModal(false);
                    // setCompany({ id: 2, name: "R1 RCM" });
                    setTripType({ id: 1, name: "Pickup" });
                    setVehicleType({ id: 1, name: "Sedan" });
                    setShiftTime({ id: 2, name: "18:00 - 03:30" });
                    setRouteId("");
                    setTripAmount("");
                    setRouteName("");
                    setTripDate(DateUtils.getCurrentDate());
                    setNumberOfPickup("");
                    setSelectedEmployee([]);
                    setPickupTimeAndRow([{ row: null, pickUpTime: "" }]);
                    setViewPickUpTime([]);
                    setCompanyError("");
                    setRouteIdError("");
                    setTripAmountError("");
                    setRouteNameError("");
                    setTripDateError("");
                    setShiftTimeError("");
                    setTripTypeError("");
                    setVehicleTypeError("");
                    setNumberOfPickupError("");
                }}
                footer={null}
                className="my-10"
                centered
            >
                {Modal1Content()}
            </Modal>
            <SuccessModal
                isOpen={successModal}
                setIsOpen={setSuccessModal}
                title={`Trip created successfully.`}
                onClose={() => setAddTripModal2(false)}
            />
        </>
    );
}
