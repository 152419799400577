import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import AccountsTable from "../tables/report-page/AccountsTable";
import AttendanceTable from "../tables/report-page/AttendanceTable";
import VehicelTripTable from "../tables/report-page/VehicleTripTable";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${
        isActive ? "text-primary-blue" : "text-placeholder-gray"
      } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function ReportTabNavigation() {
  const [activeKey, setActiveKey] = useState("accounts");
  const [userCompany, setUserCompany] = useState("");

  useEffect(() => {
    const key = sessionStorage.getItem("reportTabNavigation");
    if (key) {
      setActiveKey(key);
    }
    const company = localStorage.getItem("userTypeFleetrax");
    if (company === "lapiz" || company === "metayb") {
      setActiveKey("atendance");
    }
    setUserCompany(company);
  }, []);

  const onChange = (key) => {
    sessionStorage.setItem("reportTabNavigation", key);
    setActiveKey(key);
  };

  // Define the tab items with labels wrapped in LabelForTabs component
  const items = [
    {
      key: "accounts",
      label: (
        <LabelForTabs isActive={activeKey === "accounts"}>
          Accounts
        </LabelForTabs>
      ),
      children: <AccountsTable />,
    },
    {
      key: "mis-sheet",
      label: (
        <LabelForTabs isActive={activeKey === "mis-sheet"}>
          MIS Sheet
        </LabelForTabs>
      ),
      children: <>Mis Sheet </>,
    },
    {
      key: "atendance",
      label: (
        <LabelForTabs isActive={activeKey === "atendance"}>
          Attendance
        </LabelForTabs>
      ),
      children: <AttendanceTable />,
    },
    {
      key: "vehicle-trip",
      label: (
        <LabelForTabs isActive={activeKey === "vehicle-trip"}>
          Vehicle Trip
        </LabelForTabs>
      ),
      children: <VehicelTripTable />,
    },
  ];

  const itemsForLapiz = [
    {
      key: "atendance",
      label: (
        <LabelForTabs isActive={activeKey === "atendance"}>
          Attendance
        </LabelForTabs>
      ),
      children: <AttendanceTable />,
    },
  ];
  const itemsForMetayb = [
    {
      key: "atendance",
      label: (
        <LabelForTabs isActive={activeKey === "atendance"}>
          Attendance
        </LabelForTabs>
      ),
      children: <AttendanceTable />,
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs
        activeKey={activeKey}
        items={
          userCompany === "lapiz"
            ? itemsForLapiz
            : userCompany === "metayb"
            ? itemsForMetayb
            : items
        }
        onChange={onChange}
      />
    </div>
  );
}

export default ReportTabNavigation;
