import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, message } from "antd";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import addTrip from "../trip-management/assets/add-trip.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DropDown from "../../components/reusable/DropDown";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import DateInput from "../../components/reusable/DateInput";
import PageLayout from "../../components/layouts/PageLayout";
import EmployeeTable from "../../components/tables/employee-management/EmployeeTable";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Common from "../../services/api/commonApi";
import Employee from "../../services/api/employeeApi";

export default function EmployeeManagement({ setEmployeeData }) {
  const [addEmployeeModal, setaddEmployeeModal] = useState(false);

  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [stateName, setStateName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [pincode, setPincode] = useState("");
  const [streetName, setStreetName] = useState("");
  const [houseNumber, setHouseNumber] = useState("");

  const [employeeIdError, setEmployeeIdError] = useState("");
  const [employeeNameError, setEmployeeNameError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [whatsappNumberError, setWhatsappNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [bloodGroupError, setBloodGroupError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");
  const [stateNameError, setStateNameError] = useState("");
  const [districtNameError, setDistrictNameError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [streetNameError, setStreetNameError] = useState("");
  const [houseNumberError, setHouseNumberError] = useState("");
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [pickupLocationError, setPickupLocationError] = useState("");
  const [dropLocationError, setDropLocationError] = useState("");
  const [employeeList, setEmployeeList] = useState([]);

  const storedSearch = localStorage.getItem("employeeSearch");
  const initialSearch = storedSearch ? storedSearch : "";
  const [search, setSearch] = useState(initialSearch);

  const storedCompany = localStorage.getItem("employeeCompany");
  const initialCompany = storedCompany ? JSON.parse(storedCompany) : "";

  const [companyDD, setCompanyDD] = useState(initialCompany);

  const [createdBy, setCreatedBy] = useState("");

  const storedUser = localStorage.getItem("userTypeFleetrax");
  const initialUser = storedUser || "";
  const [userCompany, setUserCompany] = useState(initialUser);

  useEffect(() => {
    fetchEmployeeData();
    localStorage.setItem("employeeSearch", search);
  }, [search, companyDD]);

  window.onbeforeunload = () => {
    localStorage.removeItem("employeeSearch");
    localStorage.removeItem("employeeCompany");
  };

  const fetchEmployeeData = async () => {
    try {
      const data = {
        searchQuery: search,
        companyId:
          userCompany === "lapiz"
            ? 3
            : userCompany === "metayb"
            ? 7
            : companyDD.id,
      };
      const allEmployees = await Employee.findAllEmplyee(data);
      setEmployeeList(allEmployees);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };

  useEffect(() => {
    fetchCompanyData();
    fetchStateData();
    fetchBloodGroupData();
    setCreatedBy(localStorage.getItem("userIdFleetrax"));
  }, []);

  useEffect(() => {
    if (stateName) {
      fetchDistrictData();
    }
  }, [stateName]);

  const fetchCompanyData = async () => {
    try {
      const allCompany = await Common.company();
      setCompanyList(allCompany);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };
  const fetchStateData = async () => {
    try {
      const data = await Common.state();
      setStateList(data);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };
  const fetchDistrictData = async () => {
    try {
      const data = await Common.district(stateName.id);
      setDistrictList(data);
    } catch (error) {
      console.error("Error fetching community data", error);
    }
  };
  const fetchBloodGroupData = async () => {
    try {
      const allBloodGroup = await Common.bloodGroup();
      setBloodGroupList(allBloodGroup);
    } catch (error) {
      console.error("Error fetching blood-group data", error);
    }
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const BreadcrumbsUrl = () => (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleClick}>
          Trip Management
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleClick}
        >
          Not Allotted
        </Link>
        <Typography color="text.primary">LC007</Typography>
      </Breadcrumbs>
    </>
  );

  const isValid = () => {
    let isValid = true;

    if (!employeeId) {
      setEmployeeIdError("Please enter employee id");
      isValid = false;
    } else {
      setEmployeeIdError("");
    }

    if (!employeeName) {
      setEmployeeNameError("Please enter employee name");
      isValid = false;
    } else {
      setEmployeeNameError("");
    }

    if (userCompany !== "lapiz" && userCompany !== "metayb" && !company) {
      setCompanyError("Please select company");
      isValid = false;
    } else {
      setCompanyError("");
    }

    if (!gender) {
      setGenderError("Please select gender");
      isValid = false;
    } else {
      setGenderError("");
    }
    if (!mobileNumber) {
      setMobileNumberError("Please enter mobile number");
      isValid = false;
    } else {
      setMobileNumberError("");
    }
    if (!pickupLocation) {
      setPickupLocationError("Please enter pickup location");
      isValid = false;
    } else {
      setPickupLocationError("");
    }
    return isValid;
  };

  const onClickSubmit = async () => {
    if (isValid()) {
      try {
        const data = {
          employeeId: employeeId,
          employeeName: employeeName,
          gender: gender.option,
          mobileNumber: mobileNumber,
          whatsappNumber: whatsappNumber,
          email: email,
          age: age,
          dateOfBirth: dateOfBirth,
          bloodGroupID: bloodGroup.id,
          latitude: latitude,
          longitude: longitude,
          stateID: stateName.id,
          districtID: districtName.id,
          pincode: pincode,
          streetName: streetName,
          houseNumber: houseNumber,
          companyID:
            userCompany === "lapiz"
              ? 3
              : userCompany === "metayb"
              ? 7
              : company.id,
          pickUpLocation: pickupLocation,
          dropLocation: dropLocation,
          createdBy: parseInt(createdBy),
        };

        const addEmployee = await Employee.addEmployee(data);
        if (addEmployee.status === 201) {
          fetchEmployeeData();
          setaddEmployeeModal(false);
          message.success("Employee Added Successfully");
          setEmployeeId("");
          setEmployeeName("");
          setGender("");
          setMobileNumber("");
          setWhatsappNumber("");
          setEmail("");
          setAge("");
          setDateOfBirth("");
          setBloodGroup("");
          setLatitude("");
          setLongitude("");
          setStateName("");
          setDistrictName("");
          setPincode("");
          setStreetName("");
          setHouseNumber("");
          setCompany("");
          setPickupLocation("");
          setDropLocation("");
        } else {
          alert(addEmployee.message);
        }
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    }
  };

  const AddEmployeeModalContent = () => {
    return (
      <>
        <div className=" w-full  mt-6 mb-10  ">
          <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
            Add Employee
          </div>
          <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <Input
                label={"Employee ID"}
                placeholder={"Enter employee id"}
                value={employeeId}
                mandate
                onChange={(e) => setEmployeeId(e.target.value)}
                error={employeeIdError}
              />
              <Input
                label={"Employee Name"}
                placeholder={"Select employee name"}
                value={employeeName}
                mandate
                onChange={(e) => setEmployeeName(e.target.value)}
                error={employeeNameError}
              />
              {userCompany === "lapiz" ? (
                <DropDown
                  label={"Company Name"}
                  placeholder={"LAPIZ"}
                  value={"LAPIZ"}
                  mandate
                  disabled
                />
              ) : userCompany === "metayb" ? (
                <DropDown
                  label={"Company Name"}
                  placeholder={"METAYB"}
                  value={"METAYB"}
                  mandate
                  disabled
                />
              ) : (
                <DropDown
                  label={"Company Name"}
                  placeholder={"Select company name"}
                  displayValue={"name"}
                  data={companyList}
                  value={company}
                  setValue={setCompany}
                  mandate
                  error={companyError}
                />
              )}
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <DropDown
                label={"Gender"}
                placeholder={"Select gender"}
                displayValue={"option"}
                data={[{ option: "Male" }, { option: "Female" }]}
                value={gender}
                setValue={setGender}
                mandate
                error={genderError}
              />
              <MobileNumberInput
                type={"number"}
                label={"Mobile Number"}
                placeholder={"Enter mobile number"}
                value={mobileNumber}
                setValue={setMobileNumber}
                mandate
                error={mobileNumberError}
              />
              <Input
                label={"Pickup Location"}
                placeholder={"Enter pickup location"}
                value={pickupLocation}
                mandate
                onChange={(e) => setPickupLocation(e.target.value)}
                error={pickupLocationError}
              />
            </div>
            <hr className="w-full" />
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <Input
                label={"Email"}
                placeholder={"Enter email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // width={"290px"}
              />
              <Input
                type={"number"}
                label={"Age"}
                placeholder={"Enter age"}
                value={age}
                // width={"290px"}
                onChange={(e) => setAge(e.target.value)}
              />
              <DateInput
                label={"Date of Birth"}
                placeholder={"Select date"}
                value={dateOfBirth}
                setValue={setDateOfBirth}
                // width={"290px"}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
              <DropDown
                label={"Blood Group"}
                placeholder={"Enter blood group"}
                displayValue={"name"}
                data={bloodGroupList}
                value={bloodGroup}
                setValue={setBloodGroup}
                width={"290px"}
              />
              <Input
                type={"number"}
                label={"Latitude"}
                placeholder={"Enter latitude"}
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                width={"290px"}
              />
              <Input
                type={"number"}
                label={"Longitude"}
                placeholder={"Enter longitude"}
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                width={"290px"}
              />
            </div>
            <hr className="w-full" />
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
              <DropDown
                label={"State Name"}
                placeholder={"Select state name"}
                displayValue={"stateName"}
                data={stateList}
                value={stateName}
                setValue={setStateName}
              />
              <DropDown
                label={"District Name"}
                placeholder={"Select district name"}
                displayValue={"districtName"}
                data={districtList}
                value={districtName}
                setValue={setDistrictName}
              />
              <Input
                type={"number"}
                label={"Pincode"}
                placeholder={"Enter pincode"}
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
              <Input
                label={"Street Name"}
                placeholder={"Enter street name"}
                value={streetName}
                onChange={(e) => setStreetName(e.target.value)}
              />
              <Input
                label={"House Number"}
                placeholder={"Enter house number"}
                value={houseNumber}
                onChange={(e) => setHouseNumber(e.target.value)}
              />
              {/* <div className=" w-full "></div> */}
              <MobileNumberInput
                type={"number"}
                label={"Whatsapp Number"}
                placeholder={"Enter whatsapp number"}
                value={whatsappNumber}
                setValue={setWhatsappNumber}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
              {userCompany === "lapiz" ? (
                <Input
                  label={"Drop Location"}
                  placeholder={""}
                  value={company.name || "LAPIZ"}
                  disabled={true}
                  width={"290px"}
                />
              ) : userCompany === "metayb" ? (
                <Input
                  label={"Drop Location"}
                  placeholder={""}
                  value={company.name || "METAYB"}
                  disabled={true}
                  width={"290px"}
                />
              ) : (
                <Input
                  label={"Drop Location"}
                  placeholder={""}
                  value={company.name || ""}
                  disabled={false}
                  onChange={(e) => setDropLocation(e.target.value)}
                  width={"290px"}
                />
              )}

              <div className=" w-full "></div>
            </div>
          </div>
          <div className=" w-full flex  justify-center items-center">
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                onClickSubmit();
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PageLayout
        title={"Employee Management"}
        description={"View your employee details here."}
        buttonTitle={"Add Employee"}
        buttonWidth={"fit-content"}
        paddingRight={true}
        BreadcrumbsUrl={BreadcrumbsUrl}
        setAddTripModal={setaddEmployeeModal}
      >
        <div className=" w-full flex mt-7">
          <EmployeeTable
            companyList={companyList}
            setEmployeeData={setEmployeeData}
            employeeList={employeeList}
            search={search}
            setSearch={setSearch}
            company={companyDD}
            setCompany={setCompanyDD}
            userCompany={userCompany}
          />
        </div>
      </PageLayout>

      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={addEmployeeModal}
        width={"1000px"}
        onCancel={() => {
          setaddEmployeeModal(false);

          setEmployeeId("");
          setEmployeeName("");
          setGender("");
          setMobileNumber("");
          setWhatsappNumber("");
          setEmail("");
          setAge("");
          setDateOfBirth("");
          setBloodGroup("");
          setLatitude("");
          setLongitude("");
          setStateName("");
          setDistrictName("");
          setPincode("");
          setStreetName("");
          setHouseNumber("");
          setCompany("");
          setPickupLocation("");
          setDropLocation("");

          setEmployeeIdError("");
          setEmployeeNameError("");
          setCompanyError("");
          setGenderError("");
          setMobileNumberError("");
          setPickupLocationError("");
        }}
        className="my-8"
        footer={null}
        centered
      >
        {AddEmployeeModalContent()}
      </Modal>
    </>
  );
}
